





















































































import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';
import Tooltip from '@/components/tooltip/Tooltip.vue';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormAccrualCondition',
  mixins: [PromoCampaignFormMixin],

  components: {
    Tooltip
  },

  computed: {
    rules(): ValidationRules {
      return {
        accrualConditionRadioGroup: [vr.required],
        greaterAccrualConditionInput:
          this.enabledFields.accrualConditionType ===
          this.EFieldTypeKey.GREATER_THAN_OR_EQUAL
            ? this.positiveNumberRules
            : [],
        lessAccrualConditionInput:
          this.enabledFields.accrualConditionType ===
          this.EFieldTypeKey.LESS_THAN_OR_EQUAL_TO
            ? this.positiveNumberRules
            : [],
        intervalMinAccrualConditionInput:
          this.enabledFields.accrualConditionType ===
          this.EFieldTypeKey.INTERVAL_FROM_AND_TO
            ? [
                (v) => vr.validNumber(v, 'Value is not valid'),
                vr.required,
                (v) => {
                  const maxValue = this.formData.intervalToAccrualCondition;

                  return maxValue !== null && !isNaN(maxValue)
                    ? vr.lte(v, Number(maxValue))
                    : true;
                }
              ]
            : [],
        intervalMaxAccrualConditionInput:
          this.enabledFields.accrualConditionType ===
          this.EFieldTypeKey.INTERVAL_FROM_AND_TO
            ? [
                (v) => vr.validNumber(v, 'Value is not valid'),
                vr.required,
                (v) => {
                  const minValue = this.formData.intervalFromAccrualCondition;

                  return minValue !== null && !isNaN(minValue)
                    ? vr.gte(v, Number(minValue))
                    : true;
                }
              ]
            : []
      };
    }
  }
};
